import type { Entity, Establishment, Supplier, ThemeApp, Links, Term } from "./types/interfaces";

export default defineAppConfig({
    establishment: <Establishment>{},
    suppliers: <Supplier[]>[],
    entity: <Entity>{},
    terms: <Term[]>[],
    themeApp: <ThemeApp>{
        light:{},
        dark:{}
    },
    links: <Links>{
        home: "/tableau-de-bord",
        dashboard: "/tableau-de-bord",
        login: "/login",
        signup: "/signup",
        catalog: "/catalog",
        catalogWarehouseman: "/catalogue/search-warehouseman",
        contact: "/contact",
        cart: "/panier",
        devis: "/devis/Nouveau-devis",
        devisFinal: "/devis/Finalisation-Devis",
        devisHistory: "/devis/historique",
        devisDraft: "/devis/Brouillons",
        devisEdit: "/devis/edit/",
        devisShow: "/devis/show/",
        devisPrint: "/imprimer-pdf",
        ListCustomer:"/activite/clients",
        Showcustomer: "/activite/clients/show/",
        Editcustomer: "/activite/clients/edit/",
        maintenance: "/catalog/maintenance",
        maintenanceConfiguration: "/catalog/maintenance/configuration",
        maintenanceWorks: "/catalog/maintenance/works",
        maintenancePlan: "/catalog/maintenance/plan",
        manualRepairs: "/catalog/manuals",
        productList: "/products",
        orders: "/activite/commandes",
        order: "/commande",
        vehicle: "/activite/vehicules",
        legalMentions: "",
        account: "/account",
        accountSubscription: "/account/subscription",
        accountRate: "/account/rates",
        accountCustom: "/account/custom",
        accountCustomPackages: "/account/custom/packages",
        accountCustomParts: "/account/custom/parts",
        times: '/catalog/times',
        tires: '/pneumatique',
        tiresList: '/pneumatique/products',
        shop: "/boutique",
        shopSearch: "/boutique/products",
        printMaintenance: "/print/maintenance"
    },
    query: {
      actions: {
        newVehicle: 'newVehicle'
      }
    },
    version: "v1.31.5-P",
    maxImagesShop: 5
})