
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "entity": {
    "id": 13,
    "name": "DIEPEQUIPAUTO",
    "uniqueReference": "DIEPEQUIPAUTO",
    "siret": "00000000",
    "creationDate": "2024-02-22T12:10:00",
    "isActivated": true,
    "isDisabled": false,
    "deletedDate": null
  },
  "establishment": {
    "id": 22,
    "entityId": 13,
    "templateId": 1,
    "themeId": 11,
    "simultaneousUsersConnexion": 4,
    "stateMode": 1,
    "dateProduction": "2024-02-22T13:09:34.37",
    "erpOrderOrigin": "DIEPEQUIPAUTO",
    "isCheckStockBeforeOrder": false,
    "deleted": false,
    "comments": "DIEPEQUIPAUTO PROD",
    "fullUrl": "https://app.equipautopro.fr",
    "hostDomain": "app.equipautopro.fr",
    "hostIp": "127.0.0.1",
    "logoImg": null,
    "logoImgAltern": null,
    "logoText": null,
    "name": "DIEPEQUIPAUTO",
    "companyName": "DIEPEQUIPAUTO",
    "companyPublishManager": "NOM PRENOM",
    "companyPublishForm": "SASU Société par actions simplifiée à associé unique",
    "companyRcs": "XXXXXXX",
    "companySiret": "XXXXXXX",
    "companyTva": "XXXXXXX",
    "htSite": true,
    "slogan": "DIEPEQUIPAUTO",
    "describeShort": "DIEPEQUIPAUTO",
    "describeFull": "DIEPEQUIPAUTO",
    "footerText": "DIEPEQUIPAUTO",
    "horaires": "Tous les vendredi de 8h à 18h",
    "metaTitle": "DIEPEQUIPAUTO",
    "metaDescribe": "DIEPEQUIPAUTO",
    "metaKeywords": "DIEPEQUIPAUTO",
    "metaSharedImage": null,
    "metaFavIcon": null,
    "metaAuthor": "DIEPEQUIPAUTO",
    "metaAppName": null,
    "metaSubject": "DIEPEQUIPAUTO",
    "analyticsKey": "0000",
    "linkedInAccount": null,
    "facebookAccount": "DIEPEQUIPAUTO",
    "instagramAccount": "DIEPEQUIPAUTO",
    "whatsappAccount": null,
    "twitterAccount": "DIEPEQUIPAUTO",
    "youtubeAccount": "DIEPEQUIPAUTO",
    "daylimotionAccount": "DIEPEQUIPAUTO",
    "twitchAccount": "DIEPEQUIPAUTO",
    "createDate": "2024-02-23T16:17:08.99",
    "createUserId": null,
    "createUserIp": null,
    "createUserBrowser": null,
    "updateDate": null,
    "updateUserId": null,
    "updateUserIp": null,
    "updateUserBrowser": null,
    "deletedDate": null,
    "deletedUserId": null,
    "deletedUserIp": null,
    "deletedUserBrowser": null,
    "personIdEcommerce": null,
    "personIdSav": null,
    "personId": null,
    "isImportMerchantExternalOrders": false,
    "isOrdersDataSourceFromErp": false,
    "sendOrdersToErpOn": true,
    "showOnlyMerchantBrands": true,
    "deliveryAddressModifyUrl": null,
    "erpCodeClientWeb": null,
    "isConsigneIncluded": true,
    "configuration": {
      "id": 20,
      "establishmentId": 22,
      "defaultTaxId": 1,
      "erpOrderOrigin": "DIEPEQUIPAUTO PROD",
      "erpCodeClientWeb": null,
      "simultaneousUsersConnexion": 4,
      "isCheckStockBeforeOrder": true,
      "isOrdersDataSourceFromErp": false,
      "isImportMerchantExternalOrders": false,
      "deliveryAddressModifyUrl": null,
      "showOnlyMerchantBrands": true,
      "sendOrdersToErpOn": true,
      "isConsigneIncluded": true,
      "isDevis": false,
      "isWarehousemanResearch": false,
      "isVehicleMaintenance": false,
      "isManualRepair": false,
      "isTechnicalData": false,
      "isGraphicResearch": true,
      "isTechnicalCharacteristicsParts": false,
      "isProfessionalAdvice": false,
      "isShop": false,
      "isCart": true,
      "isPneumatic": true,
      "showAvailabilityStock": true,
      "showQuantityStock": true,
      "sessionTime": 24,
      "showBranchName": true,
      "showAutopartsproLogo": true,
      "isDiscount": true,
      "isPublicPrices": true,
      "isActiveMddTecdocBrands": null,
      "smtpMainId": 1,
      "smtpQuoteId": 1,
      "mediasUrl": null,
      "technicalDataLicenceType": "4",
      "technicalDataLicenceDuration": "M",
      "warningMaintenanceText": null,
      "createAccountText": "<p><span class=font-semibold>Félicitations</span>, votre nouveau compte client a bien été créé !<p>Veuillez noter que celui-ci est maintenant en attente d'activation par nos services.<p>Nous vous informerons par mail sur l'adresse <span class=font-semibold>« ${email} »</span> dès qu'il sera disponible.<p>Merci pour votre demande et à très bientôt.",
      "tax": {
        "id": 1,
        "countryId": 1001,
        "title": "20%",
        "percentage": 20,
        "isActive": true
      },
      "mainSmtp": {
        "id": 1,
        "title": "APP Main",
        "description": "Utilsation principale AutoPartsPro",
        "ip": "mail.mailfr.biz",
        "port": 587,
        "login": "no-reply@mailfr.biz",
        "password": "Tre1272@",
        "email": "no-reply@mailfr.biz",
        "sslActive": true,
        "tlsActive": false,
        "forceSenderName": null,
        "forceReplyTo": null,
        "isOn": true,
        "dateCreated": null
      },
      "quoteSmtp": {
        "id": 1,
        "title": "APP Main",
        "description": "Utilsation principale AutoPartsPro",
        "ip": "mail.mailfr.biz",
        "port": 587,
        "login": "no-reply@mailfr.biz",
        "password": "Tre1272@",
        "email": "no-reply@mailfr.biz",
        "sslActive": true,
        "tlsActive": false,
        "forceSenderName": null,
        "forceReplyTo": null,
        "isOn": true,
        "dateCreated": null
      }
    },
    "phone": null,
    "email": null
  },
  "suppliers": [
    {
      "id": 1,
      "typeId": 2,
      "typeTitle": "Catalogue pièce auto",
      "title": "TECDOC Pegasus 3.0",
      "description": "Webservice TECDOC Pegasus 3.0",
      "catalog": null,
      "frontCallEndPoint": "https://wstdc.autopartspro.fr",
      "devFrontCallEndPoint": "https://wstdc.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:28.923",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 2,
      "typeId": 3,
      "typeTitle": "Données techniques",
      "title": "TECRMI",
      "description": "Webservice TecRMI",
      "catalog": null,
      "frontCallEndPoint": "https://wsrmi.autopartspro.fr",
      "devFrontCallEndPoint": "https://dev-wsrmi.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:54.1",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 16,
      "typeId": 4,
      "typeTitle": "ERP",
      "title": "AGRA SOFT",
      "description": "Webservice AGRA",
      "catalog": null,
      "frontCallEndPoint": "https://wserp4.autopartspro.fr",
      "devFrontCallEndPoint": "https://dev-wserp4.autopartspro.fr",
      "dateCreated": "2024-07-10T17:15:45.45",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 11,
      "typeId": 6,
      "typeTitle": "Service de l'application",
      "title": "ShoppingCart",
      "description": "Panier et commandes",
      "catalog": null,
      "frontCallEndPoint": "https://wscart.autopartspro.fr",
      "devFrontCallEndPoint": "https://wscart.autopartspro.fr",
      "dateCreated": "2024-02-01T00:00:00",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 13,
      "typeId": 5,
      "typeTitle": "SIV (Plaques)",
      "title": "AAA Slvin",
      "description": "Webservice AAA Slvin",
      "catalog": null,
      "frontCallEndPoint": "https://wspl.autopartspro.fr",
      "devFrontCallEndPoint": "https://wspl.autopartspro.fr",
      "dateCreated": "2023-10-18T17:24:02.56",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    }
  ],
  "terms": [],
  "links": {
    "cdnUrl": "https://cdn.autopartspro.fr"
  },
  "themeApp": {
    "light": {
      "mainColor": "#FFCD00",
      "secondaryColor": "#000000",
      "terciaryColor": null,
      "neutral23": "#3B393D",
      "shades76": "#eec3165c",
      "neutral45": "#000000",
      "neutral71": "#727272",
      "neutral77": "#000000",
      "shades94": "#eec3174d",
      "borderBtnWhite": "#DDE2E8",
      "btnSearch": "#FFCD00",
      "btnLinear": "#FFCD00",
      "background97": "#F9F8F9",
      "roundedBorder": "0.5rem",
      "dispo": "",
      "dispoSoon": "",
      "indispo": "",
      "inLogistics": "",
      "defaultFontColor": "#000000"
    },
    "dark": {}
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
